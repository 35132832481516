import React from "react";
import { withRouter } from "react-router-dom";

import { connect } from "@cerebral/react";
import { state } from "cerebral";

import "./index.scss";

class GenderSwitch extends React.Component {
  goTo(ageGroup, gender) {
    return () => {
      let curAgeGroup = ageGroup;
      if (!curAgeGroup) {
        curAgeGroup = this.props.curAgeGroup;
      }

      let curGender = gender;
      if (!curGender) {
        curGender = this.props.curGender;
      }

      if (curAgeGroup.slug === "teens" && curGender.slug === "boys") {
        curGender = this.props.genders[0];
      }

      let curCollection = this.props.curCollection;
      if (!this.props.curCollection) {
        curCollection = this.props.firstCollection;
      }

      this.props.history.push(
        `/shop/${curAgeGroup.slug}/${curGender.slug}/${curCollection.slug}/`,
      );
    };
  }

  render() {
    return (
      <div>
        <div className="gender-switch">
          {this.props.ageGroups
            .filter((ageGroup) => {
              if (ageGroup.slug === "kids") {
                return (
                  this.props.curCollection?.hasKidsGirls ||
                  this.props.curCollection?.hasKidsBoys
                );
              }

              return this.props.curCollection?.hasTeensGirls;
            })
            .map((ageGroup) => (
              <div
                key={"age-group-switch_" + ageGroup.slug}
                className={
                  "gender-switch__item" +
                  (this.props.curAgeGroup.slug === ageGroup.slug
                    ? " gender-switch__item--active"
                    : "")
                }
                onClick={this.goTo(ageGroup, null)}
              >
                {ageGroup.title}
              </div>
            ))}
        </div>
        <div className="gender-switch">
          {this.props.genders
            .filter((gender) =>
              this.props.curAgeGroup.slug === "teens"
                ? gender.slug !== "boys"
                : true,
            )
            .map((gender) => (
              <div
                key={"gender-switch_" + gender.slug}
                className={
                  "gender-switch__item" +
                  (this.props.curGender.slug === gender.slug
                    ? " gender-switch__item--active"
                    : "")
                }
                onClick={this.goTo(null, gender)}
              >
                {gender.title}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    {
      ageGroups: state.shop.ageGroups,
      curAgeGroup: state.shop.ageGroup,
      genders: state.shop.genders,
      curGender: state.shop.gender,
      curCollection: state.shop.collection,
      firstCollection: state.shop.firstCollection,
      curCategory: state.shop.category,
      showAgeGroups: state.shop.showAgeGroups,
    },
    GenderSwitch,
  ),
);
