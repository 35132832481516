import React from "react";

import { connect } from "@cerebral/react";
import { state, sequences} from "cerebral";
import { withRouter } from "react-router-dom";

import GenderList from "../../molecules/gender-list/index";
import AgeGroupList from "../../molecules/age-group-list/index";

class ShopHome extends React.Component {
  static defaultProps = {
    firstCollection: null,
  };

  componentDidMount() {
    this.props.loadCollections();
  }
  render() {
    if (!this.props.firstCollection) {
      return null;
    }

    if (this.props.firstCollection.hasAgeGroups) {
      return <AgeGroupList />;
    } else {
      return <GenderList />;
    }
  }
}

export default withRouter(
  connect(
    {
      firstCollection: state.shop.firstCollection,
      loadCollections: sequences.shop.loadCollections,
    },
    ShopHome,
  ),
);

