import React from "react";

import { connect } from "@cerebral/react";
import { state } from "cerebral";
import { withRouter } from "react-router-dom";

import SidebarNav from "../sidebar-nav/index";
import SidebarItem from "../../atoms/sidebar-item/index";
import GenderSwitch from "../gender-switch/index";

class CollectionNav extends React.Component {
  goToCollection(collection) {
    console.log(collection);
    if (collection.hasKidsGirls) {
      return this.props.history.push(
        "/shop/kids/girls/" + collection.slug + "/",
      );
    }

    if (collection.hasKidsBoys) {
      return this.props.history.push(
        "/shop/kids/boys/" + collection.slug + "/",
      );
    }

    if (collection.hasTeensGirls) {
      return this.props.history.push(
        "/shop/teens/girls/" + collection.slug + "/",
      );
    }

    return this.props.history.push("/shop/kids/girls/" + collection.slug + "/");
  }

  render() {
    if (!this.props.collection || !this.props.collections) {
      return null;
    }

    let collections = this.props.collections.map((collection) => {
      return (
        <SidebarItem
          key={collection.id}
          style={this.props.collection.id === collection.id ? "active" : null}
          text={collection.title}
          onClick={() => this.goToCollection(collection)}
        />
      );
    });

    return (
      <div className="collection-nav">
        <GenderSwitch />
        <SidebarNav>{collections}</SidebarNav>
      </div>
    );
  }
}

export default withRouter(
  connect(
    {
      ageGroup: state.shop.ageGroup,
      gender: state.shop.gender,
      collections: state.shop.collections,
      collection: state.shop.collection,
    },
    CollectionNav,
  ),
);

